
import { Spotlight } from "./components/spotlight";


function App() {
  return (
    <div>
     <Spotlight/>
    </div>
  );
}
export default App;
